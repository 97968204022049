// src/pages/DashboardWelcomePage.jsx
import React, { useContext, useState, useEffect } from "react";
import { RiRefreshLine } from "react-icons/ri"; // Correct icon import
import Layout from "../components/Layout/layout";
import StockPredictionChart from "../components/StockPredictionChart";
import { AuthContext } from "../context/AuthContext";
import useStockPrediction from "../hooks/useStockPrediction";
import { ClipLoader } from "react-spinners";
import { PiWarningCircle } from "react-icons/pi";
import DashboardDisclaimer from "../components/Modals/DashboardDisclaimer.jsx";


const DashboardWelcomePage = () => {
    const { authState } = useContext(AuthContext);
    const {
        ticker,
        setTicker,
        predictionData,
        loading,
        error,
        handleGeneratePrediction,
    } = useStockPrediction();

    const tickersRow1 = ["AAPL", "NVDA", "GOOGL", "QQQ"];
    const tickersRow2 = ["TSLA", "META", "BTC-USD"];

    const formatPrice = (price) =>
        price !== null && price !== undefined
            ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
              }).format(price)
            : "N/A";

    const calculateChange = (predictedPrice, lastPrice) => {
        if (predictedPrice == null || lastPrice == null) return "N/A";
        return (((predictedPrice - lastPrice) / lastPrice) * 100).toFixed(2);
    };

    // State to manage modal visibility
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Open modal on component mount
    useEffect(() => {
        setIsModalOpen(true); // Open modal every time the page loads
    }, []);

    // Handle closing the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (!authState.user) {
        return <p>Loading user data...</p>;
    }

    return (
        <Layout title={`Hey ${authState.user.name || "there"} 👋`}>
            {/* Disclaimer Modal */}
            <DashboardDisclaimer isOpen={isModalOpen} onClose={handleCloseModal}>
                <h2 id="disclaimer-title" className="text-xl font-bold mb-4">Disclaimer:</h2>
                <p className="text-base text-gray-700 mb-4">
                    Trenviz employs advanced probabilistic models, statistical analysis, and machine learning algorithms to generate a 5-day likelihood price prediction profile for stocks, ETFs, commodities, and cryptocurrencies.
                </p>
                <p className="text-base text-gray-700 mb-4">
                    Trenviz's output also identifies technical support and resistance levels.
                </p>
                <p className="text-base text-gray-700 mb-4">
                    It is strongly recommend to validate your predictions daily before each market open to account for news or events induced volatility which are unpredictable.
                </p>
                <p className="text-base text-gray-700">
                    By using Trenviz, you acknowledge that all predictions are based on historical data and market analysis. Past performance does not guarantee future results. Please use this information responsibly.
                </p>
            </DashboardDisclaimer>

            <div
                className="bg-cover bg-center bg-no-repeat w-full py-8 px-4"
                style={{ backgroundImage: "url('/assets/images/chart.png')" }}
            >
                <div className="max-w-6xl mx-auto bg-white rounded-lg p-8 py-10 flex flex-col md:flex-row gap-6">
                    {/* Main Content Area */}
                    <div className="flex-1 text-center md:text-left">
                        <h2 className="text-[30px] leading-[50px] md:text-[40px] lg:text-[54px] font-bold text-black-100 mb-4 font-bricolage">
                            Welcome to Trenviz
                        </h2>
                        <p className="text-black-100 mb-6 font-dmSans">
                            Enter a ticker symbol in the search bar to explore market trends and insights.
                        </p>

                        {/* Popular Tickers */}
<div className="flex flex-col items-center space-y-4 font-dmSans">
    <div className="flex flex-wrap gap-4">
        {tickersRow1.map((tickerItem, index) => (
            <button
                key={index}
                onClick={() => setTicker(tickerItem)} // Only sets the ticker
                className={`hidden sm:flex items-center gap-2 px-4 py-2 ${
                    ticker === tickerItem ? "bg-pink-200" : "bg-[#FF6F610A]"
                } text-[#2D3142] rounded-full text-2sm leading-[20.83px] shadow-sm hover:bg-pink-200 transition`}
            >
                <span>{tickerItem}</span>
                <span className="text-lg font-bold">+</span>
            </button>
        ))}
    </div>

    <div className="flex gap-4">
        {tickersRow2.map((tickerItem, index) => (
            <button
                key={index}
                onClick={() => setTicker(tickerItem)} // Only sets the ticker
                className={`hidden sm:flex items-center gap-2 px-4 py-2 ${
                    ticker === tickerItem ? "bg-pink-200" : "bg-[#FF6F610A]"
                } text-[#2D3142] rounded-full text-2sm leading-[20.83px] shadow-sm hover:bg-pink-200 transition`}
            >
                <span>{tickerItem}</span>
                <span className="text-lg font-bold">+</span>
            </button>
        ))}
    </div>
</div>

{/* Search Bar */}
<div className="flex items-center bg-white rounded-3xl border border-[#2D3142] overflow-hidden py-2 mt-10">
    <input
        type="text"
        placeholder="Type ticker here"
        value={ticker}
        onChange={(e) => setTicker(e.target.value)} // Updates the ticker value
        className="flex-1 px-4 py-3 focus:outline-none text-base uppercase"
    />
   
</div>

{/* Generate Prediction Button */}
<div className="flex justify-center mt-6">
    <button
        onClick={handleGeneratePrediction} // Trigger prediction manually
        className={`bg-[#2A9D8F] text-white py-3 px-6 rounded-full font-dmSans hover:bg-teal-600 flex items-center justify-center ${
            loading ? "bg-gray-400 cursor-not-allowed" : ""
        }`}
        disabled={loading} // Disable button while loading
    >
        <span>{loading ? "Generating..." : "Generate Prediction"}</span>
    

                                <RiRefreshLine color="#FFC107" size="22px" className="ml-2" />
                            </button>
                        </div>

                        {/* Loading Spinner or Prediction Chart */}
                        {loading ? (
                            <div className="flex flex-col items-center justify-center h-64 mt-6">
                                <ClipLoader color="#38b6ff" size={100} />
                                <p className="mt-4 text-sm text-gray-600">
                                    Running Simulations. Please wait, this may take a few moments...
                                </p>
                            </div>
                        ) : predictionData ? (
                            <div className="mt-6">
                                <StockPredictionChart chartData={predictionData.chartData} formatPrice={formatPrice} />
                            </div>
                        ) : (
                            <p className="text-center text-gray-500 mt-6">Enter a ticker to see predictions.</p>
                        )}

                        {/* Display Error Message */}
                        {error && (
                            <div className="mt-4 text-red-500">
                                <p>Error: {error}</p>
                            </div>
                        )}
                        </div>

                    {/* Sidebar - Prediction Summary, Market Sentiment, and Key Levels */}
                    {predictionData && (
                        <div className="w-full md:w-1/3 bg-white rounded-lg border border-gray-200 p-6 shadow-sm flex flex-col">
                         {/* Prediction Summary */}
<div className="mb-6">
    <h3 className="text-lg font-semibold mb-4">Prediction Summary</h3>
    <div className="space-y-3 text-gray-700">
        <div className="grid grid-cols-2 items-center">
            <span>Current Price</span>
            <span className="font-medium text-right">
                {formatPrice(predictionData.lastPrice)}
            </span>
        </div>
        <div className="grid grid-cols-2 items-center">
            <span>Predicted Price (5d)</span>
            <span className="font-medium text-right">
                {formatPrice(predictionData.predictedPrice)}
            </span>
        </div>
        <div className="grid grid-cols-2 items-center">
            <span>Change</span>
            <span
                className={`font-medium text-right ${
                    predictionData.predictedPrice > predictionData.lastPrice
                        ? "text-green-500"
                        : "text-red-500"
                }`}
            >
                {calculateChange(
                    predictionData.predictedPrice,
                    predictionData.lastPrice
                )}%
            </span>
        </div>
    </div>
</div>

                            {/* Market Sentiment */}
                             {/*
                            <div className="mb-6">
                                <h3 className="text-lg font-semibold mb-4">Market Sentiment</h3>
                                <div className="space-y-3 text-gray-700">
                                    <div className="flex justify-between items-center">
                                        <span className="font-medium text-gray-600">Regime:</span>
                                        <span className={`px-2 py-1 rounded-full ${predictionData.marketRegime}`}>
                                            {predictionData.marketRegime || "Unknown"}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span>Volatility</span>
                                        <span className="font-medium rounded-full p-2 bg-[#F4A2611A] text-[#F4A261] border border-[#F4A261]">
                                            Medium
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span>Trend</span>
                                        <span className="font-medium rounded-full p-2 bg-[#28A7451A] text-[#28A745] border border-[#28A745]">
                                            Bullish
                                        </span>
                                    </div>
                                </div>
                            </div>
                            */}

                            {/* Key Levels */}
                            <div className="mb-6">
                                <h3 className="text-lg font-semibold mb-4">Key Levels</h3>
                                <div className="space-y-3 text-gray-700">
                                    <div className="flex justify-between">
                                        <span>Resistance</span>
                                        <span className="font-semibold">
                                            {formatPrice(predictionData.resistance)}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span>Support</span>
                                        <span className="font-semibold">
                                            {formatPrice(predictionData.support)}
                                        </span>
                                    </div>
                                   {/*
                                    <div className="flex justify-between items-center">
                                        <span>Stop Loss</span>
                                        <span className="font-semibold text-[#DC3545]">
                                            {formatPrice(predictionData.stopLoss)}
                                        </span>
                                    </div>
                                    */}
                                </div>
                            </div>

                            
                        </div>
                    )}
                </div>

                {/* Bottom Disclaimer */}
                <div className="bg-[#FF9F1C14] rounded-lg p-2 w-fit text-[#FF9F1C] flex items-center mx-auto mt-4">
                    <PiWarningCircle color="#FF9F1C" size="22px" />
                    <p className="text-[12.9px] font-medium ml-2 flex-1">
                        Disclaimer: Predictions are based on historical data and market analysis. Past performance does not guarantee future results. Always conduct your own research before making investments.
                    </p>
                </div>
                </div>
            </Layout>
        );
    };

export default DashboardWelcomePage;
